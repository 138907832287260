.footer-page{
    background-color: #444e5a;
    overflow: hidden;
}

.footer-page p{
    color: #e6e6e6;
    margin-bottom: 0px;
}
.footer-page .logo-mob h6{
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 30px;
}
.footer-page .logo-mob ul{
    list-style: none;
}
.footer-page .logo-mob ul li a{
    color: #afafaf;
    display: block;
    padding: 5px 0;
}
footer .footer-copyright{
    color: #bbbbbb;
    background: #4f5b69;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: .9rem;
}  
footer .footer-copyright a{
    color: #dcdcdc;
    padding: 0 5px;
}
.popup_cookie{
    background: rgba(41, 44, 46, 0.9) none repeat scroll 0 0 !important;
    bottom: 0px;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1100;
}
.popup_cookie .popup-container{
    padding: 13px;
    text-align: center;
}
.popup_cookie p{
    color: #fff;
}
.popup_cookie .popup-container a.btn{
    margin-left: 5px;
    padding: 3px 10px;
}