*{
    margin: 0px;
    padding: 0px;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.bg-transparent{
    background-color: transparent;
}
.bg-white{
    background-color: white;
}
.navbar{
    padding: 0.8rem 1rem;
}
.nav-fixed{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
}
.text-left{
  text-align: left;
}
.navbar-expand-lg{
    box-shadow: 1px 1px 70px rgba(0, 0, 0, 0.2);
}
.navbar-collapse .nav .nav-item .nav-link:not(.active):hover{
    background: #f1f1f1;
}
@media (min-width: 995px){
    .navbar-expand-lg .navbar-collapse.display-inline-block{
        display: inline-block !important;
    }
}
@media (max-width: 995px)/* and (min-width: 600px)*/{
    .navbar-expand-lg .navbar-collapse.display-inline-block:not(.show) {
        display: none ;
    }
    .navbar-collapse{
        margin-top: 20px;
    }
}

.nav-item{
    margin: 0px 10px; 
}
.navbar-light .navbar-nav .nav-link{
    font-weight: 500;
    line-height: 1rem;
    color: #000;
    padding: 13px;
}
.navbar-light .navbar-nav .nav-link.active{
    font-weight: 500;
    color: #ffff;
    line-height: 1rem;
    box-shadow: 2px 6px 14px rgba(0, 123, 255, 0.4);
}
.navbar-light .navbar-nav .nav-link.active .icon{
    fill: #ffff;
}
.navbar-light .navbar-nav .nav-link .icon{
    position: relative;
    top: -2px;
    fill: #000;
}

.right{
    float: right;
}
.btn-loader{
    position: relative;
    top: -6px;
}
.btn-loader {
   display: block;
 }
 .btn-loader span {
   display: inline-block;
   margin-top: 0;
   height: 5px;
   width: 5px;
   border-radius: 50%;
 }
 .btn-loader span:not(:first-child) {
   margin-left: 3px;
 }
 .btn-loader span:nth-child(1) {
   background: #cccccc;
   animation: move 0.5s ease-in-out -0.25s infinite alternate;
 }
 .btn-loader span:nth-child(2) {
   background: #cccccc;
   animation: move 0.5s ease-in-out -0.5s infinite alternate;
 }
 .btn-loader span:nth-child(3) {
   background: #cccccc;
   animation: move 0.5s ease-in-out -0.75s infinite alternate;
 }
 .btn-loader span:nth-child(4) {
   background: #cccccc;
   animation: move 0.5s ease-in-out -1s infinite alternate;
 }
 
 @keyframes move {
   from {
     transform: translateY(0px);
   }
   to {
     transform: translateY(7px);
   }
 }

@media (min-width: 1570px){
  .card_f54w .card-body .card-title p {
    /*width: 100% !important;*/
  }
}

@media (max-width: 991px){
  .navbar-light .navbar-nav .nav-link{
    padding: 15px;
  }
}

.card_f54w:hover .card-body .card-title p{
  /*text-overflow: unset;
  width: auto !important;
  overflow: initial;
  z-index: 1;*/
}
.card_f54w:hover .card-body .card-title p span{
  /*background: #f6f6f6;
  position: relative;
  z-index: 1;
  padding-right: 10px;*/
}

.loader{
  width: 100%;
  text-align: center;
}

.loader-{
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 99999999;
}
.loader-.show{
  display: block;
}
.loader- .loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgb(195, 195, 195);
  margin-left: -60px;
  margin-top: -60px;
}
.loader- .loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  -o-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  -webkit-animation: rotate 1.2s infinite linear;
  -moz-animation: rotate 1.2s infinite linear;
  -o-animation: rotate 1.2s infinite linear;
  animation: rotate 1.2s infinite linear;
}

.loader- .loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgb(0, 0, 0);
}
@-webkit-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-moz-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-o-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-webkit-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-moz-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-o-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-webkit-keyframes rotate{ 0% { -webkit-transform: rotate(0deg);} 100% { -webkit-transform: rotate(360deg);}}
@-moz-keyframes rotate{ 0% { -moz-transform: rotate(0deg);} 100% { -moz-transform: rotate(360deg);}}
@-o-keyframes rotate{ 0% { -o-transform: rotate(0deg);} 100% { -o-transform: rotate(360deg);}}
@keyframes rotate{ 0% {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);} 100% {-webkit-transform: rotate(360deg);-moz-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);}}


.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn{
  opacity:1;
  width:100%;
  height:100%;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.loader-logo{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -13.4px;
  width: 60px;
}
.card-preview{
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 3px;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  min-height: 150px;
}
.card-preview.show{
  opacity: 1;
}
.card-preview .card-title{
  padding: 15px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  margin: 30px 20px;
  border-radius: 3px;
  width: 80%;
}
.card-preview .card-t1{
  padding: 7px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  margin: 9px 20px;
  width: 50%;
  border-radius: 3px;
}
.card-preview .card-t2{
  padding: 5px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  margin: 6px 20px;
  width: 60%;
  border-radius: 3px;
}
.display-flex{
  display: flex;
}
.scheleton-text-h1{
  width: 30%;
  height: 50px;
  border-radius: 3px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  color: transparent;
  margin: 10px;
  margin-left: 0px;
}
.scheleton-button{
  width: 150px;
  height: 50px;
  border-radius: 3px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  color: transparent;
  margin-left: 20px;
  margin: 10px;
}
.scheleton-text-h2{
  width: 40%;
  height: 38px;
  border-radius: 3px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  color: transparent;
  margin: 10px;
  margin-left: 0px;
}
.scheleton-text-p{
  width: 25%;
  height: 26px;
  border-radius: 3px;
  background: #dedfe0;
  background-image: -webkit-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -moz-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -ms-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: -o-linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  background-image: linear-gradient(top left, rgba(222, 223, 224, .5), rgba(204, 204, 204, .5));
  color: transparent;
  margin: 10px;
  margin-left: 0px;
  margin-bottom: 1rem;
}
.pg-load{
  min-height: calc(100vh - 290.77px);
}

.loader-progressBar{
  background-color: rgb(181, 211, 240);
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  border-radius: 2px;
  margin: 0;
  top: 66px;
  overflow: hidden;
  z-index: 9999;
}
.loader-progressBar .loader-pload{
  background-color: #1976d2;
}
.loader-progressBar .loader-pload:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate .55s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate .55s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.loader-progressBar .loader-pload:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

@-webkit-keyframes indeterminate-short{
  0%{
    left:-200%;
    right:100%
  }
  60%{
    left:107%;
    right:-8%
  }
  100%{
    left:107%;
    right:-8%
  }
}
@keyframes indeterminate-short{
  0%{
    left:-200%;
    right:100%
  }
  60%{
    left:107%;
    right:-8%
  }
  100%{
    left:107%;
    right:-8%
  }
}

.align-center{
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  display: flex;
}
@media (max-width: 755px){
  .logo-mob{
    text-align: center;
    margin: 10px;
  }
}

