body.word section .title{
    margin-bottom: 20px;
}
body.word section.word_sect{
    padding-top: 50px;
    background: #f7f7f7;
}
body.word section h1.title_word{
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    word-wrap: break-word;
}
body.word section h3.subtitle_word{
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;
}
body.word section p.description{
    font-size: 1.2rem;
    width: 100%;
    word-wrap: break-word;
}
body.word section button.play_casdf{
    margin-top: -11px;
    padding-top: 4px;
    width: 160px;
    height: 40px;
}
body.word section button.play_casdf2{
    margin-top: 50px;
    height: 41px;
    line-height: 10px;
    margin-right: 10px;
    font-size: 1rem;
}
body.word .card_f54w .card-body:hover .card-title p span{
    background: #eeeeee;
    position: relative;
    z-index: 1;
    padding-right: 10px;
}
body.word button{
    position: relative;
}
body.word .progressbar{
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

body.word .progressbar .in_progress{
    background-color: #60b9ec;
    width: 10%;
    position: absolute;
    left: 0px;
    height: 100%;
}
body.word .progressbar.pausa .in_progress{
    background-color: red;
}

body.word .slider_item_word .card_f54w{
    width: 360px;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}
body.word .card_f54w:hover .card-body .card-title p span {
    background: #eeeeee;
    position: relative;
    z-index: 1;
    padding-right: 10px;
}

@media (max-width: 1575px){
    body.word .slider_item_word .card_f54w{
        margin-right: 12px;
    }
}
@media (max-width: 500px){
    body.word .slider_item_word .card_f54w{
        width: 320px;
        margin-right: 10px;
    }
    body.word section h1.title_word{
        font-size: 1.8rem;
    }
    body.word section h3.subtitle_word{
        font-size: 1.5rem;
    }
    body.word section p.description{
        font-size: 1rem;
    }
}
@media (max-width: 375px){
    body.word .slider_item_word .card_f54w{
        width: 270px;
        margin-right: 10px;
    }
    body.word .card_f54w .card-body .card-title{
        display: grid;
    }
    body.word section h1.title_word{
        font-size: 1.5rem;
    }
    body.word section h3.subtitle_word{
        font-size: 1.5rem;
    }
    body.word section p.description{
        font-size: 1rem;
    }
    body.word section.word_sect{
        padding: 10px;
    }
}