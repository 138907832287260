
.slider-card{
    margin-top: 40px;
    margin-bottom: 80px;
}
.slider_menu{
    position: relative;
}
.menu-item-wrapper{
    outline: none !important;
}
.slider_item {
    display: inline-flex;
    padding: 0 30px;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
}
.slider_body{
    position: relative;
}
.box_slider{
    position: relative;
}
.box_slider .full-card{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
body.categories .slider-card .slider_menu .slider_item{
    opacity: .5;
}
body.categories .slider-card .slider_menu .slider_item.active{
    opacity: 1;
}
.slider_body .scroll-menu-arrow--disabled{
    display: none;
}
.slider_body .scroll-menu-arrow .arrow-next, .slider_body .scroll-menu-arrow .arrow-prev{
    background: white;
    position: absolute;
    right: 10px;
    border-radius: 100%;
    border: 0;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, .25);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .25);
    outline: none;
    height: 40px;
    width: 40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    text-align: center;
    line-height: 40px;
}
.slider_body .scroll-menu-arrow .arrow-prev{
    left: 10px;    
}