body.home{
    background-image: url(../../assets/img/header-home.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 90%;
}

.header-home{
    min-height: 660px;
}
section#categories{
    margin-top: 100px;
}
section .title{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #3a3a3a;
}
section .subtitle{
    text-align: center;
    font-size: 1.1rem;
}
section .button-section{
    margin-top: 30px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.5);
}
section.bg-f9fafb{
    background-color: #f9fafb;
}
.sectionpadd{
    padding: 100px;
}
.box_slider, .box_slider img{
    text-align: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.box_slider .icon_slider{
    width: 250px;
    margin: 0px auto;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.box_slider .icon_slider::selection {
    background-color: transparent;
    color: #000;
}
.box_slider h3{
    margin-top: 30px;
    font-weight: 700;
}

.no-lateral-padd{
    padding-left: 10px;
    padding-right: 10px;
}
.lateral-30-padd{
    padding-left: 50px;
    padding-right: 50px;
}
.card_f54w{
    margin: 30px;
    min-height: 130px;
    border: none;
    box-shadow: 0px 0px 30px 0 rgba(121, 121, 121, 0.1);
    background: -moz-linear-gradient(top, rgba(121, 121, 121,0.1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top, rgba(121, 121, 121,0.1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(121, 121, 121,0.1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03000000', endColorstr='#00ffffff',GradientType=0 );
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
}
.card_f54w .card-header{
    background-color: transparent;
    border: none;
    text-align: left;
}
.card_f54w .card-header .label-card{
    width: 150px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .175rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.card_f54w .card-header .label-card:hover{
    background-color: #007bff;
}
.card_f54w .card-header .button-card-play{
    border-radius: 5px;
    margin-left: 40px;
    font-size: .6rem;
    position: relative;
    z-index: 1;
}
.card_f54w .card-body{
    text-align: left;
    padding-top: 5px;
    padding-right: 55px;
}
.card_f54w .card-body .card-title{
    font-size: 1.5rem;
    font-weight: 400;
    color: #3a3a3a;
}
.card_f54w .card-body .card-text{
    font-size: .9rem;
    margin-bottom: 0px;
    color: #5a5a5a;
}
.card_f54w .card-body small{
    color:#616161;
}
.card_f54w .card-body .link{
    position: absolute;
    right: 0;
    top: 0px;
    height: 100%;
    padding: 20px;
    font-size: 1.8rem;
    color: #8e8e8e;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
}
.card_f54w .card-body .link svg{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    height: 100%;
}
.socialIcon:hover{
    color: #fff;
}
.socialIcon{
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    font-size: 25px;
    display: inline-block;
    margin-right: 20px;
}
.socialIcon.fb {
    background: #507cbe;
}
.socialIcon.ins {
    background: #c03484;
}
.socialIcon.tw {
    background: #00aced;
}
.card_f54w:hover{
    transform: scale(.9);
}
@media (max-width:966px){
    .card_f54w .card-header .button-card-play{
        margin-left: 30px;
    }
}
@media (min-width:1200px){
    .card_f54w .card-header .button-card {
        width: 125px;
    }
    .card_f54w .card-header .button-card-play {
        margin-left: 10px;
    }
    
}

@media (min-width:1024px) and (max-width: 1070px){
    .card_f54w .card-header .button-card-play{
        float: right;
        margin: 5px 0;
    }  
}
@media (min-width:1024px) and (max-width: 1575px){
    .sectionpadd{
        padding-left: 30px;
        padding-right: 30px;
    }
    .card_f54w{
        margin-left: 0px;
        margin-right: 0px;
    }
    
}
@media(max-width: 1024px){
    /*.card_f54w .card-header .button-card{
        width: 130px;
    }*/
    .card_f54w .card-header .button-card-play{
        float: right;
        margin: 5px 0;
    }  
    .sectionpadd{
        padding-left: 30px;
        padding-right: 30px;
    }
    .card_f54w{
        margin-left: 0px;
        margin-right: 0px;
    }
}
@media (max-width: 825px){
    .sectionpadd{
        padding-left: 30px;
        padding-right: 30px;
    }
    .card_f54w{
        margin-left: 0px;
        margin-right: 0px;
    }
}
@media (min-width: 575px) and (max-width: 665px){
    .card_f54w .card-header .button-card{
        width: 100px;
    }
}
@media (max-width: 320px){
    .card_f54w .card-header .button-card{
        width: 115px;
    }
}
.search_nav{
    min-height: 100%;
    margin-left: -10%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    margin-top: 230px;
    z-index: 10;
    position: relative;

}
.search_nav form {
    width: 100%;
    max-width: 790px;
}
fieldset {
    margin: 0;
    padding: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
    border: 0;
}
fieldset {
    padding: 0.35em 0.75em 0.625em;
}
.search_nav form legend {
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
}
.search_nav form .inner-form {
    width: 100%;
    margin-bottom: 17px;
}
.search_nav form .inner-form .input-field {
    height: 70px;
    width: 100%;
    position: relative;
}
.search_nav form .inner-form .input-field .btn-search {
    width: 70px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    height: 100%;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    outline: none;
}
.search_nav form .inner-form .input-field .btn-search svg {
    fill: #0584ff;
    color: #0584ff;
    width: 15px;
    height: 15px;
    transition: all .2s ease-out, color .2s ease-out;
}
svg:not(:root) {
    overflow: hidden;
}
.search_nav form .inner-form .input-field input {
    height: 100%;
    width: 100%;
    background: transparent;
    border: 0;
    background: #fff;
    display: block;
    width: 100%;
    padding: 10px 32px 10px 70px;
    font-size: 1.1rem;
    color: #666;
    border-radius: 10px; /*34px*/
    outline: none;
    box-shadow: 27px 28px 29px rgba(0, 0, 0, 0.14901960784313725);
    transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -webkit-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -moz-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -o-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
}
.search_nav form .words-wrap {
    padding: 0 15px;
}
.search_nav form .words-wrap span {
    font-size: 14px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.45);
    padding: 0 15px;
    line-height: 32px;
    color: #fff;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
}



@media (max-width: 1760px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 200px;
    }
    .header-home{
        min-height: 560px;
    }
}
@media (max-width: 1560px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 180px;
    }
    .header-home{
        min-height: 500px;
    }
}
@media (max-width: 1500px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 200px;
    }
    .header-home{
        min-height: 400px;
    }
}
@media (max-width: 1440px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 7%;
        transform: scale(0.8);
    }
}
@media (max-width: 1200px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 7%;
        transform: scale(0.7);
    }
}
@media (max-width: 1024px)/* and (min-width: 600px)*/{
    .search_nav{
        margin-top: 6%;
        transform: scale(0.7);
    }
}
@media (max-width: 950px)/* and (min-width: 600px)*/{
    
    .search_nav {
        margin-top: 7%;
        transform: scale(0.8);
        margin-left: 0%;
    }    
    body.home {
        background-image: none;
        background-repeat: no-repeat;
        background-position: 0px 60px;
        background-size: 100%;
    }
    .header-home{
        min-height: auto;
    }
    .header-home{
        background-image: url(../../assets/img/pattern.jpg);
        background-repeat: no-repeat;
        background-position: 0px 0;
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 40px;
    }
}


/* Slide */
@media (min-width: 1360px){
    .slick-slide_r .slick-track{
        margin-left: 160px;
    }
}
@media (min-width: 750px){
    .slick-slide_.slick-active{
        margin-right: 15px;
    }
    .slick-slide_r .slick-track{
        margin-left: 135px;
    }
}
@media (max-width: 671px){
    .search_nav{
        margin-top: 5%;
    } 
}
@media (max-width: 605px){
    .search_nav form legend{
        font-size: 3rem;
    } 
    .search_nav{
        width: 100%;
        padding: 0px;
    }
}
@media (max-width: 600px){
    .slick-slide_:first-child{
        display: none;
    } 
    .header-home{
        margin-top: 30px;
    }
}
@media (max-width: 575px){
    .card_f54w .card-body .card-title p{
        width: auto !important;
        margin-right: 10px;
        
        overflow: inherit !important;
        white-space: inherit !important;
        text-overflow: unset !important;
    }
    .card_f54w{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 500px){
    .search_nav form legend{
        font-size: 2.5rem;
    } 
    .search_nav{
        margin-top: 0%;
    }
    .words-wrap a:last-child{
        display: none;
    }
    fieldset{
        padding: 0px;
    }
    .slick-slide_{
        margin-left: -10px;
    }
    .slick-slide_:last-child{
        display: none;
    }
    .header-home{
        margin-top: 60px;
    }
    legend{
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
    }
}
@media (max-width: 425px){
    .slick-slide_:first-child, .slick-slide_:last-child{
        display: none;
    } 
    body.home{
        background-image: none;
        background-repeat: no-repeat;
        background-position: 0px 66px;
        background-size: 100%;
    }
}


.slick-slide_.slick-active{
    margin-right: 15px;
}
.search_nav form .inner-form .input-field input.focused{
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid #f2f2f2;
}
.loadAutocompleteLists {
    position: relative;
    top: -20px;    
    list-style: none;
    background: #fff;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    display: none;
    opacity: 0;
    height: 50px;
    transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -webkit-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -moz-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    -o-transition: .3s cubic-bezier(0.29, 0.16, 0.4, 1.01);
    width: 100%;
    z-index: 1000;
}
.loadAutocompleteLists.show{
    display: block;
    height: auto; 
}
.loadAutocompleteLists.show.anim{
    opacity: 1;
    top: 0px;
    border-top: 0px;
    box-shadow: 0 15px 26px 0 rgba(0, 0, 0, 0.06);
}
.loadAutocompleteLists p.sub_t{
    margin: 0 25px;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 500;
    color: #7d7d7d;
    padding-bottom: 10px;
    padding-top: 20px;
}
.loadAutocompleteLists ul.list{
    margin-bottom: 0px;
}
.loadAutocompleteLists ul.list:last-child{
    margin-bottom: 1rem;
}
.loadAutocompleteLists ul.list li{
    display: grid;
}
.loadAutocompleteLists ul.list li a{
    padding: 10px 15px 10px 28px;
    color: #000;
    display: grid;
    font-weight: 500;
}
.loadAutocompleteLists ul.list li a small{
    color: #5a5a5a;
    font-weight: 600;
}
.loadAutocompleteLists ul.list li a small strong{
    font-weight: 700;
}
.loadAutocompleteLists ul.list li a:hover{
    background: #f5f5f5;
    text-decoration: none;
}
a.no-decoration{
    text-decoration: none;
}
.label-card-play{
    background-color: transparent;
    border: 1px solid #343a40;
    color: #343a40;
    padding: .2rem .2rem;
    font-size: .7rem;
    line-height: 1.4;
    border-radius: .2rem;
    display: initial;
    position: relative;
    top: -3px;
}
.card-footer{
    text-align: left;
}
.card_f54w .card-body .card-title {
    display: -webkit-box;
}
.card_f54w .card-body .card-title p{
    width: 74%;
    overflow: inherit/*hidden*/;
    white-space: inherit/*nowrap*/;
    text-overflow: ellipsis;
}
@media (max-width: 375px){
    .card_f54w .card-body .card-title{
        display: grid;
    }
}