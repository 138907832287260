.contact .contactus-1 .info .icon {
    margin-top: 14px;
    color: #fff;
}
.contact .icon.icon-primary {
    color: #9c27b0;
}
.contact .info-horizontal .icon {
    float: left;
    margin-top: 24px;
    margin-right: 10px;
}
.contact svg.icons{
    font-size: 1.5rem;
    position: relative;
    top: -20px;
    color:#3a3a3a;
}
.contact .description{
    margin-left: 40px;
}
.contact .description p{
    font-size: 14px;
    margin: 0 0 10px;
}
.contact .info{
    margin-bottom: 30px;
}

body.contact .inputForm {
    padding-left: 54px !important;
    padding: 16px;
    border: 1px solid #cfd9db;
    background-color: #ffffff;
    border-radius: .25em;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    height: auto;
    color: #2b3e51;
    position: relative;
}
body.contact .inputForm:focus{
    border-color: #007bff;
}
body.contact .inputForm.error{
    border-color: #da4242;
}
body.contact .inputForm.name{
    background: url(../../assets/svg/cd-icon-user.svg) no-repeat 16px center,url(../../assets/svg/cd-required.svg) no-repeat top right;
}
body.contact .inputForm.email{
    background: url(../../assets/svg/cd-icon-email.svg) no-repeat 16px center,url(../../assets/svg/cd-required.svg) no-repeat top right;
}
body.contact .inputForm.message{
    background: url(../../assets/svg/cd-icon-message.svg) no-repeat 16px 16px ,url(../../assets/svg/cd-required.svg) no-repeat top right;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
}
body.contact .inputForm.budget{
    background: url(../../assets/svg/cd-icon-budget.svg) no-repeat 16px center;
}

body.contact .inputForm:focus{
    /*box-shadow: 27px 28px 29px rgba(0, 0, 0, 0.14901960784313725);*/
}
body.contact .inputForm [type="file"]{
    display: none; 
}
body.contact .inputForm .form-label{
    margin-bottom: 0px;
}
body.contact .inputForm.file{
    height: 58px;
}
body.contact .inputForm.file .form-label{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 18px 18px 18px 55px;
    cursor: pointer;
}


body.contact .btn{
    border: none;
    background: #2c97de;
    border-radius: .25em;
    padding: 16px 20px;
    color: #fff;
    font-weight: 700;
    float: right;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}
body.contact .p_contact{
    margin-right: 25px;
    text-align: justify;
}

@media (max-width: 767px){
    body.contact .onlyMoby{
        margin-top: 30px;
    }
    body.contact .btn-moby {
        float: unset;
        width: 100%;
    }
}