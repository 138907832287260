body.testo section .title{
    margin-bottom: 20px;
}
body.testo section.word_sect{
    padding-top: 50px;
    background: #f7f7f7;
}
body.testo section h1.title_word{
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    width: 100%;
    word-wrap: break-word;
}
body.testo section h3.subtitle_word{
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;
}
body.testo section p.description{
    font-size: 1.2rem;
    width: 100%;
    word-wrap: break-word;
}
body.testo section button.play_casdf{
    margin-top: 17px;
    margin-left: 10px;
    padding-top: 4px;
    width: 160px;
    height: 40px;
}
body.testo section button.play_casdf2{
    margin-top: 50px;
    height: 41px;
    line-height: 10px;
    margin-right: 10px;
    font-size: 1rem;
}
body.testo section h1.title_word{
    display: flex;
}