
body.categories section#categories{
    margin-top: -14px;
    padding: 80px 0 1px;
}

body.categories .search_nav{
    margin: 0px;
    padding: 0px;
    margin-bottom: 60px;
    z-index: 10;
    position: relative;
}
body.categories .loader-ctn{
    margin: 65px auto;
}
body.categories .search_nav form .inner-form .input-field input{
    border: 1px solid #ececec;
}
@media (max-width: 450px){
    section .title{
        font-size: 2rem;
    }
    body.categories .search_nav{
        transform: scale(1);
    }
}