body.requests .inputForm {
    padding-left: 54px !important;
    padding: 16px;
    border: 1px solid #cfd9db;
    background-color: #ffffff;
    border-radius: .25em;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    height: auto;
    color: #2b3e51;
    position: relative;
}
body.requests .inputForm.name{
    background: url(../../assets/svg/cd-icon-user.svg) no-repeat 16px center,url(../../assets/svg/cd-required.svg) no-repeat top right;
}
body.requests .inputForm.email{
    background: url(../../assets/svg/cd-icon-email.svg) no-repeat 16px center,url(../../assets/svg/cd-required.svg) no-repeat top right;
}
body.requests .inputForm.message{
    background: url(../../assets/svg/cd-icon-message.svg) no-repeat 16px 16px ,url(../../assets/svg/cd-required.svg) no-repeat top right;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
}
body.requests .inputForm.budget{
    background: url(../../assets/svg/cd-icon-budget.svg) no-repeat 16px center;
}

body.requests .inputForm:focus{
    border-color: #007bff;
}
body.requests .inputForm.error{
    border-color: #da4242;
}
body.requests .inputForm [type="file"]{
    display: none;
}
body.requests .inputForm .form-label{
    margin-bottom: 0px;
}
body.requests .inputForm.file{
    height: 58px;
}
body.requests .inputForm.file .form-label{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 18px 18px 18px 55px;
    cursor: pointer;
}


body.requests .btn{
    border: none;
    background: #2c97de;
    border-radius: .25em;
    padding: 16px 20px;
    color: #fff;
    font-weight: 700;
    float: right;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

@media (max-width: 535px){
    .noMoby{
        display: none;
    }
}

@media (max-width: 767px){
    .noMoby{
        /*margin-top: 30px;
        text-align: center;*/
        display: none;
    }
    body.requests .btn-moby {
        float: unset;
        width: 100%;
    }
}
